import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { OidcProvider } from '@axa-fr/react-oidc';

import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import configuration from './Configuration';
import App from './Application';

import './assets/i18n';
import SessionLostComponent from './home/shared/SessionLostComponent';
import AuthenticatingComponent from './home/shared/AuthenticatingComponent';
import LoadingComponent from './home/shared/LoadingComponent';

const container = document.getElementById('root')!;
const root = createRoot(container);

const onSessionLost = () => {
  console.log('Session lost CCDTN');
};

root.render(
  <React.Fragment>
    <CssBaseline />
    <BrowserRouter>
      <OidcProvider
        configuration={configuration}
        sessionLostComponent={SessionLostComponent}
        authenticatingComponent={AuthenticatingComponent}
        loadingComponent={LoadingComponent}
        // onSessionLost={onSessionLost}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </OidcProvider>
    </BrowserRouter>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
