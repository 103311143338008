import React, { useState, useEffect, forwardRef } from 'react';

import { useTheme, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, FormControl, Card, CardActions, CardContent } from '@mui/material';

import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { resetPasswordAsync, selectCurrentUser, selectUsers, updateUserAsync } from './userSlice';
import UploadAvatar from '../Upload/UploadAvatar';
import { userServerAddress } from '../../../common/MetaSetting';
import { useTranslation } from 'react-i18next';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { MessageType } from '../../../model/MemoModelShapes';
import { appendMessageAsync } from '../../../appSlice';

export function UserPanel(props: any) {
  const theme = useTheme();
  const { t } = useTranslation();

  const currentUser = useAppSelector(selectCurrentUser);
  const users = useAppSelector(selectUsers);
  const dispatch = useAppDispatch();

  const [editUserName, setEditUserName] = useState<string | undefined>(currentUser?.userName);
  const [openEdit, setOpenEdit] = useState(false);

  const [editPassword, setEditPassword] = useState('');
  const [editConfirmPassword, setEditConfirmPassword] = useState('');
  const [openReset, setOpenReset] = useState(false);

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { fetch } = useOidcFetch();

  const handleOnClose = () => {
    if (isCurrentUserChanged()) {
      dispatch(appendMessageAsync({ messageType: MessageType.WARNING, message: t('warningMessageChangeNotSave') }));
      return;
    }

    setOpenEdit(false);
  };

  const handleEditUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditUserName(event.target.value);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditSave = () => {
    let userClone = Object.assign({}, currentUser);
    dispatch(
      updateUserAsync({
        oidcFetch: fetch,
        userInfo: Object.assign(userClone, { userName: editUserName })
      })
    );
    setOpenEdit(false);
  };

  const handleEdit = () => {
    setEditUserName(currentUser?.userName);
    if (currentUser) {
      setOpenEdit(true);
    }
  };

  const handleResetPassword = () => {
    setOpenReset(true);
  };

  const handleOnResetClose = () => {
    if (editPassword || editConfirmPassword) {
      dispatch(appendMessageAsync({ messageType: MessageType.WARNING, message: t('warningMessageChangeNotSave') }));
      return;
    }
    setOpenReset(false);
  };

  const handleEditPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditPassword(event.target.value);
  };

  const handleEditConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditConfirmPassword(event.target.value);
  };

  const handleResetClose = () => {
    setOpenReset(false);
    setEditPassword('');
    setEditConfirmPassword('');
  };

  const handleReset = () => {
    if (currentUser && currentUser.userId) {
      dispatch(resetPasswordAsync({ oidcFetch: fetch, password: editPassword }));
    }

    setOpenReset(false);
    setEditPassword('');
    setEditConfirmPassword('');
  };

  const isCurrentUserChanged = () => {
    let result = !(currentUser.userName === editUserName && !editPassword && !editConfirmPassword);
    return result;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
        padding: '0.2em',
        backgroundColor: 'secondary.light'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'start',
          flexGrow: 0,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 0,
            margin: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px',
              height: '100px'
            }}
          >
            <img style={{ maxWidth: '100px', height: 'auto' }} src={currentUser && currentUser.avatarPath && `${userServerAddress}${currentUser?.avatarPath}`} />
          </Box>
          <UploadAvatar></UploadAvatar>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            margin: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5" component="div">
              {currentUser?.userName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-arround',
                alignItems: 'center'
              }}
            >
              <Button size="small" onClick={handleResetPassword}>
                {t('buttonResetPassword')}
              </Button>
              <Button variant="contained" size="small" onClick={handleEdit}>
                {t('buttonEdit')}
              </Button>
            </Box>
          </Box>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {currentUser?.userId}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          flexGrow: 0,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }}
      >
        {users &&
          users
            .filter((u) => u.id !== currentUser.id)
            .map((user) => (
              <Card sx={{ minWidth: 250, maxWidth: 300, margin: '5px' }} key={user.source}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {user.source}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {user.name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {user.userName}
                  </Typography>
                  <Typography variant="body2">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            ))}
      </Box>
      <Dialog open={openEdit} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleUserInformation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Update User. */}</DialogContentText>
          <FormControl sx={{ p: 1, width: '100%' }} size="small">
            <TextField
              autoFocus
              id="editUserName"
              label={t('labelUserName')}
              type="text"
              fullWidth
              variant="standard"
              value={editUserName}
              onChange={handleEditUserNameChange}
              // onKeyDown={handleEditKeyPress}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openReset} onClose={handleOnResetClose}>
        <DialogTitle>{t('titleResetPassword')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Reset password. */}</DialogContentText>
          <TextField id="resetPassword" label={t('labelPassword')} type="password" fullWidth variant="standard" value={editPassword} onChange={handleEditPasswordChange} />
          <TextField id="resetConfirmPassword" label={t('labelConfirmPassword')} type="password" fullWidth variant="standard" value={editConfirmPassword} onChange={handleEditConfirmPasswordChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetClose} variant="outlined" color="primary">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleReset} variant="contained" color="error">
            {t('buttonReset')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
