export type UISectionShape = {
  userId: string;
  guid: string;
  createTimestamp: number;
  stateCode: number;
  modifyTimestamp: number;
  name: string;
  sectionType: number;
  hasShare: boolean;
};

export type UIGroupShape = {
  userId: string;
  guid: string;
  createTimestamp: number;
  stateCode: number;
  description: string;
  modifyTimestamp: number;
  name: string;
  sectionId: string;
  hasShare: boolean;
};

export type UIMemoContentShape = {
  userId: string;
  guid: string;
  createTimestamp: number;
  stateCode: number;
  content: string;
  modifyTimestamp: number;
};

export type UIMemoShape = {
  userId: string;
  guid: string;
  title: string;
  createTimestamp: number;
  modifyTimestamp: number;
  stateCode: number;
  groupId: string;
  syncStateCode: number;
  tags: UITagListShape | undefined;
  hasShare: boolean;
  updateStatus: number;
};

export type UIRepresentationListShape = {
  guid: string;
  representations: UIRepresentationShape[];
};

export type UIRepresentationShape = {
  guid: string;
  createTimestamp: number;
  content: string;
};

export type UITagListShape = {
  guid: string;
  tag: UITagShape;
};

export type UITagShape = {
  guid: string;
  createTimestamp: number;
  content: string;
};

export type UITrainingListShape = {
  guid: string;
  tag: UITagShape;
};

export type UITrainingShape = {
  guid: string;
  createTimestamp: number;
  content: string;
};

export type UITagItemShape = {
  label: string;
  level: string;
};

export type UIMemoSearchShape = {
  userId: string;
  guid: string;
  title: string;
  createTimestamp: number;
  modifyTimestamp: number;
  stateCode: number;
  groupId: string;
  hit: number;
};

export type UIMemoIndexShape = {
  tag: string;
  memos: UIMemoShape[];
};

export type SectionSyncRequestShape = {
  syncType: number;
  GUID: string;
  sourceSection: UISectionShape;
};

export type SectionSyncResponseShape = {
  syncResult: number;
  syncedSection: UISectionShape;
};

export type GroupSyncRequestShape = {
  syncType: number;
  GUID: string;
  sourceGroup: UIGroupShape;
};

export type GroupSyncResponseShape = {
  syncResult: number;
  syncedGroup: UIGroupShape;
};

export type MemoSyncRequestShape = {
  syncType: number;
  GUID: string;
  sourceMemo: UIMemoShape;
  sourceMemoContent: string;
};

export type MemoSyncResponseShape = {
  syncResult: number;
  syncedMemo: UIMemoShape;
};

export type ShareSyncRequestShape = {
  syncType: number;
  guid: string;
  sourceShare: UIShareStatusShape;
};

export type ShareSyncResponseShape = {
  syncResult: number;
  syncedShare: UIShareStatusShape;
};

export type UIShareStatusShape = {
  guid: string;
  createTimestamp: number;
  modifyTimestamp: number;
  shareStatus: UIShareInfoShape[];
};

// export type UIMemoPropertyResponseShape = {
//   guid: string;
//   representations: UIRepresentationListShape[];
//   tag: UITagListShape | undefined;
// };

export type UIShareInfoShape = {
  targetType: number;
  targetId: string;
};

export type UIShareTargetShape = {
  id: string;
  label: string;
};

// export type UIObjectShareShape = {
//   guid: string;
//   shareStatus: UIShareShape[];
//   createTimestamp: number;
//   modifyTimestamp: number;
// };

export type UIShareShape = {
  typeMark: number;
  title: string;
  guid: string;
  createTimestamp: number;
  modifyTimestamp: number;
  isPublic: boolean;
  targetUserGroupIds: UIShareTargetShape[];
  targetUserIds: UIShareTargetShape[];
};

export type UIUserGroupShape = {
  userId: string;
  guid: string;
  createTimestamp: number;
  stateCode: number;
  modifyTimestamp: number;
  name: string;
  userMarks: UIShareTargetShape[];
};

export type UserGroupSyncRequestShape = {
  syncType: number;
  GUID: string;
  sourceUserGroup: UIUserGroupShape;
};

export const HttpResponseType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  NO_AUTH: 2,
  ERROR: 3
};

export type CommandRequestShape = {
  commandType: number;
  commandContent: string;
};

export type TrainingRequestShape = {
  trainingType: number;
  trainingSourceId: string;
};

export const CommandType = {
  UNKNOWN: 0,
  EXE: 1,
  ANALYSIS: 2
};

export const HttpResponseErrorId = {
  UNKNOWN: 'errorIdUnknown',
  EXCEPTION: 'errorIdException'
};

export type FetchResponseShape = {
  responseType: number;
  message: string;
  content: any;
};

export const MessageType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4
};

export type MessageShape = {
  guid: string;
  messageType: number;
  message: string;
};

export type UIPhraseShape = {
  userId: string;
  guid: string;
  createTimestamp: number;
  stateCode: number;
  content: string;
  contentType: number;
  modifyTimestamp: number;
  title: string;
  tags: string;
  isNew: boolean;
};
