import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { SOURCE_ID, wmServerAddress } from '../../../common/MetaSetting';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';
import { CommandRequestShape } from '../../../model/MemoModelShapes';

export function sendTrainingMessage(oidcFetch: Function, commandType: number, commandContent: string) {
  let command: CommandRequestShape = { commandType: commandType, commandContent };
  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'POST',
    body: JSON.stringify(command)
  });

  return oidcFetch(`${wmServerAddress}/command/run`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
