import React from 'react';

import { Box, Card, CardContent, FormControl, IconButton, Input, InputAdornment, InputLabel, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Person, Key, VisibilityOff, Visibility } from '@mui/icons-material';

let source: any = {};

export default function Account(props: any) {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  let { indexKey, account } = props;

  if (account && account.nodeIdentity === '34')
    source = Object.assign(source, {
      label: account.ID_LABEL31,
      account: account.ACCOUNT23,
      password: account.PASSWORD29
    });
  else return <></>;

  return (
    <Card sx={{ minWidth: 250, maxWidth: 300, margin: '1em' }} key={`account-card-${indexKey}`}>
      <CardContent>
        <Typography variant="h5" component="div">
          {source.label}
        </Typography>
        <FormControl variant="standard" disabled>
          <InputLabel htmlFor={`input-with-icon-adornment-${indexKey}`}>Account</InputLabel>
          <Input
            id={`input-with-icon-adornment-${indexKey}`}
            defaultValue={source.account}
            startAdornment={
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="standard" disabled>
          <InputLabel htmlFor={`standard-adornment-password-${indexKey}`}>Password</InputLabel>
          <Input
            id={`standard-adornment-password-${indexKey}`}
            type={showPassword ? 'text' : 'password'}
            defaultValue={source.password}
            startAdornment={
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </CardContent>
    </Card>
  );
}
