import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';

interface ChipArrayProps {
  limit: number;
  values: any[] | undefined;
  onValueDeleted: (value: string | undefined) => void;
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

export function ChipArray(props: ChipArrayProps) {
  const { t } = useTranslation();

  const { limit, values, onValueDeleted, ...other } = props;

  const [chipData, setChipData] = React.useState<any[] | undefined>(values);

  useEffect(() => {
    setChipData(values);
  }, [values]);

  const handleDelete = (chipToDelete: any) => () => {
    if (onValueDeleted) onValueDeleted(chipToDelete.id);
  };

  return (
    <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
      {values?.map((data) => {
        return <Chip key={data.id} label={`${data.label} (${data.id})`} onDelete={handleDelete(data)} />;
      })}
    </Stack>
  );
}
