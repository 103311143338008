import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { wmServerAddress } from '../../../common/MetaSetting';
import { UISectionShape, SectionSyncRequestShape, UIShareShape, UIShareInfoShape, UIShareStatusShape, ShareSyncRequestShape } from '../../../model/MemoModelShapes';
import { SHARE_SETTING_TYPE_MARK } from '../../../common/MetaData';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';

export function fetchSectionShares(oidcFetch: Function, lastSyncTimestamp = 0) {
  return oidcFetch(`${wmServerAddress}/share/sections/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchSections(oidcFetch: Function, lastSyncTimestamp = 0) {
  // console.log("section lastSyncTimestamp", lastSyncTimestamp);
  return oidcFetch(`${wmServerAddress}/section/all/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchSection(oidcFetch: Function, sectionId: string) {
  return oidcFetch(`${wmServerAddress}/section/${sectionId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchSectionShare(oidcFetch: Function, sectionId: string) {
  return oidcFetch(`${wmServerAddress}/share/section/${sectionId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteSectionShareSync(oidcFetch: Function, section: UIShareShape) {
  let shareInfo: UIShareInfoShape[] = [];
  if (section.isPublic)
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.PUBLIC,
      targetId: ''
    });
  section.targetUserGroupIds.map((userSection) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER_GROUP,
      targetId: userSection.id
    });
  });
  section.targetUserIds.map((user) => {
    shareInfo.push({
      targetType: SHARE_SETTING_TYPE_MARK.USER,
      targetId: user.id
    });
  });
  let shareStatus: UIShareStatusShape = {
    guid: section.guid,
    createTimestamp: section.createTimestamp,
    modifyTimestamp: section.modifyTimestamp,
    shareStatus: shareInfo
  };
  var sectionSyncRequest: ShareSyncRequestShape = {
    syncType: 0,
    guid: section.guid,
    sourceShare: shareStatus
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(sectionSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/share/section/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteSectionSync(oidcFetch: Function, section: UISectionShape) {
  var sectionSyncRequest: SectionSyncRequestShape = {
    syncType: 0,
    GUID: section.guid,
    sourceSection: section
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(sectionSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/section/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
