import React from 'react';

import { Box } from '@mui/material';

import { UserPanel } from '../components/user/UserPanel';
import { OidcSecure } from '@axa-fr/react-oidc';
import { mainContentMarginTopSecondary } from '../../common/MetaSetting';

export const AccountPage = () => {
  return (
    <Box
      sx={{
        height: `calc(100vh - ${mainContentMarginTopSecondary})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        padding: '0.5em'
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'stretch'
        }}
      >
        <UserPanel></UserPanel>
      </Box>
    </Box>
  );
};

export const SecureAccountPage = () => (
  <OidcSecure>
    <AccountPage />
  </OidcSecure>
);

export default SecureAccountPage;
