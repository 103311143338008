import React from 'react';

import { useTranslation } from 'react-i18next';
import { OidcSecure } from '@axa-fr/react-oidc';
import { Box } from '@mui/material';
import { mainHeaderHeight } from '../../common/MetaSetting';
import { SearchPanel } from '../components/search/SearchPanel';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: `calc(100vh - ${mainHeaderHeight})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: '1em'
      }}
    >
      <SearchPanel></SearchPanel>
    </Box>
  );
};

export const SecureDashboard = () => (
  <OidcSecure>
    <Dashboard />
  </OidcSecure>
);

export default SecureDashboard;
