import React, { useEffect, useState } from 'react';

import { Box, Typography, Breadcrumbs, Link } from '@mui/material';

import { SectionList } from '../components/section/SectionList';
import { GroupList } from '../components/group/GroupList';
import { MemoList } from '../components/memo/MemoList';

import { DEFAULT_GROUP_GUID, DEFAULT_SECTION_GUID, leftListWidth, maxWidthGroupBreadcrumb, maxWidthSectionBreadcrumb } from '../../common/MetaSetting';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectCurrentSection, updateSectionShareAsync, changeSelectedSectionId } from '../components/section/sectionSlice';
import { selectCurrentGroup, updateGroupShareAsync, changeSelectedGroupId } from '../components/group/groupSlice';
import { selectCurrentMemo, updateMemoShareAsync } from '../components/memo/memoSlice';
import { MemoPanel } from '../components/memo/MemoPanel';
import { selectCurrentShare } from '../shareSlice';
import { SharePanel } from '../components/SharePanel';
import { UIShareShape } from '../../model/MemoModelShapes';
import { selectCurrentListPanel, selectCurrentViewPanel, changeCurrentListPanel } from '../../appSlice';
import { MEMO_PAGE_LIST_PANEL, MEMO_PAGE_VIEW_PANEL, SHARE_TYPE_MARK, getEmptyShare } from '../../common/MetaData';
import { OidcSecure, useOidcFetch } from '@axa-fr/react-oidc';

const EditPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fetch } = useOidcFetch();

  const currentSection = useAppSelector(selectCurrentSection);
  const currentGroup = useAppSelector(selectCurrentGroup);
  const currentMemo: any = useAppSelector(selectCurrentMemo);
  const [memoObject, setMemoObject] = useState(currentMemo);
  const currentShare = useAppSelector(selectCurrentShare);
  const currentListPanel = useAppSelector(selectCurrentListPanel);
  const currentViewPanel = useAppSelector(selectCurrentViewPanel);

  useEffect(() => {
    setMemoObject(currentMemo);
  }, [currentMemo]);

  const handlePathStartClick = () => {
    dispatch(changeCurrentListPanel(MEMO_PAGE_LIST_PANEL.SECTION));
    // dispatch(changeSelectedSectionId(''));
    // dispatch(changeSelectedGroupId(''));
  };

  const handlePathSectionClick = () => {
    dispatch(changeCurrentListPanel(MEMO_PAGE_LIST_PANEL.GROUP));
    // dispatch(changeSelectedGroupId(''));
  };

  const handleShareSave = (shareSetting: UIShareShape) => {
    let shareObject = Object.assign(getEmptyShare(), { guid: shareSetting.guid, setting: shareSetting });
    switch (shareSetting?.typeMark) {
      case SHARE_TYPE_MARK.SECTION:
        dispatch(updateSectionShareAsync({ oidcFetch: fetch, shareObject: shareObject }));
        break;
      case SHARE_TYPE_MARK.GROUP:
        dispatch(updateGroupShareAsync({ oidcFetch: fetch, shareObject: shareObject }));
        break;
      case SHARE_TYPE_MARK.MEMO:
        dispatch(updateMemoShareAsync({ oidcFetch: fetch, shareObject: shareObject }));
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch'
      }}
    >
      <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch', backgroundColor: 'background.paper', padding: '0.2em' }}>
        <Breadcrumbs
          maxItems={3}
          sx={{
            flexGrow: 0,
            width: leftListWidth,
            overflowWrap: 'break-word'
          }}
        >
          <Link underline="none" sx={{ color: 'primary' }} href="#" onClick={handlePathStartClick}>
            <Typography sx={{ maxWidth: maxWidthSectionBreadcrumb }} component="span">
              {t('memoPathStart')}
            </Typography>
          </Link>
          {(currentListPanel === MEMO_PAGE_LIST_PANEL.GROUP || currentListPanel === MEMO_PAGE_LIST_PANEL.MEMO) &&
            currentSection &&
            (currentGroup ? (
              <Link underline="none" sx={{ color: 'primary', maxWidth: maxWidthSectionBreadcrumb }} href="#" onClick={handlePathSectionClick}>
                <Typography noWrap sx={{ maxWidth: maxWidthSectionBreadcrumb }}>
                  {currentSection.guid === DEFAULT_SECTION_GUID ? t('titleDefaultSection') : currentSection.name}
                </Typography>
              </Link>
            ) : (
              <Typography noWrap sx={{ maxWidth: maxWidthSectionBreadcrumb, color: 'text.primary' }}>
                {currentSection.guid === DEFAULT_SECTION_GUID ? t('titleDefaultSection') : currentSection.name}
              </Typography>
            ))}
          {currentListPanel === MEMO_PAGE_LIST_PANEL.MEMO && currentSection && currentGroup && (
            <Typography noWrap sx={{ maxWidth: maxWidthGroupBreadcrumb, color: 'text.primary' }}>
              {currentGroup.guid === DEFAULT_GROUP_GUID ? t('titleDefaultGroup') : currentGroup.name}
            </Typography>
          )}
        </Breadcrumbs>
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 300,
            maxWidth: 350
          }}
        >
          {currentListPanel === MEMO_PAGE_LIST_PANEL.SECTION && <SectionList />}
          {currentListPanel === MEMO_PAGE_LIST_PANEL.GROUP && currentSection && <GroupList />}
          {currentListPanel === MEMO_PAGE_LIST_PANEL.MEMO && currentGroup && <MemoList />}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'stretch'
        }}
      >
        {currentViewPanel === MEMO_PAGE_VIEW_PANEL.EDIT && memoObject && <MemoPanel />}
        {currentViewPanel === MEMO_PAGE_VIEW_PANEL.SHARE && currentShare && <SharePanel onShareSave={handleShareSave} />}
      </Box>
    </Box>
  );
};

export const SecureEditPage = () => (
  <OidcSecure>
    <EditPage />
  </OidcSecure>
);

export default SecureEditPage;
