import { Dashboard, Dns, Person, Group, ImportContacts } from '@mui/icons-material';

import { IMenuItem } from '../types';
import { ROUTES } from './routes';

export const MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.dashboard,
    literal: 'menuDashboard',
    Icon: Dashboard
  },
  {
    route: ROUTES.edit,
    literal: 'menuMemos',
    Icon: Dns
  }
];

export const SYSTEM_MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.user_group,
    literal: 'menuUserGroup',
    Icon: Group
  },
  {
    route: ROUTES.account,
    literal: 'menuAccount',
    Icon: Person
  }
];

export const LIB_MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.training,
    literal: 'menuTraining',
    Icon: ImportContacts
  }
];
