import React, { useEffect, useRef } from 'react';

import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

export function Pattern(props: any) {
  const { t } = useTranslation();

  let { pattern } = props;
  console.log('pattern', pattern);
  useEffect(() => {}, [pattern]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        padding: '0.2em'
      }}
    >
      {/* {pattern && <div>{pattern.content}</div>} */}
      <Typography>{pattern.patternType}</Typography>
      {pattern.patterns &&
        pattern.patterns.length > 0 &&
        pattern.patterns.map((patternString: any, index: any) => {
          return <Typography key={index}>{patternString.pattern}</Typography>;
        })}
    </Box>
  );
}
