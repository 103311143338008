import React from 'react';

import { PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';
import { mainFooterHeight } from '../../common/MetaSetting';

export const Footer = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme();

  if (!children) {
    return null;
  }

  return (
    <Box
      sx={{
        flexGrow: 0,
        height: mainFooterHeight,
        background: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        textAlign: 'center'
      }}
      className="copyright"
    >
      {children}
    </Box>
  );
};
