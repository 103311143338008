import { HttpResponseErrorId, HttpResponseType } from '../model/MemoModelShapes';

export default function isArraiesEqualByGUID(arraySource: any[] | undefined, arrayTarget: any[] | undefined) {
  if (!arraySource && !arrayTarget) return true;
  if (!arraySource) return false;
  if (!arrayTarget) return false;

  if (arraySource.length != arrayTarget.length) return false;

  let matchedCount = 0;
  arraySource.forEach((itemSource) => {
    let targets = arrayTarget.filter((itemTarget) => itemTarget.guid === itemSource.guid);
    if (targets && targets.length > 0) {
      matchedCount++;
    }
  });
  return matchedCount === arrayTarget.length;
}

export const fetchHttpResponseHandler = (response: any) => {
  if (response.status === 404 || response.status === 403 || response.status === 401 || response.status >= 500) return fetchErrorHandler(response.status);
  else return fetchResponseHandler(response.json());
};

const fetchResponseHandler = (response: any) => {
  return {
    responseType: HttpResponseType.SUCCESS,
    message: undefined,
    content: response
  };
};

const fetchErrorHandler = (httpStatus: number) => {
  console.log('httpStatus', httpStatus);
  let responseType = HttpResponseType.ERROR;
  switch (httpStatus) {
    case 401:
    case 403:
      responseType = HttpResponseType.NO_AUTH;
      break;
    case 404:
      responseType = HttpResponseType.UNKNOWN;
      break;
    default:
      break;
  }
  return {
    responseType: responseType,
    message: HttpResponseErrorId.EXCEPTION,
    content: undefined
  };
};

export const fetchHttpExceptionHandler = () => {
  return {
    responseType: HttpResponseType.ERROR,
    message: HttpResponseErrorId.EXCEPTION,
    content: undefined
  };
};
