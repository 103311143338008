import React, { useRef, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { updateMemoPropertiesAsync, selectCurrentMemoContent, selectCurrentEditMemoContent, updateMemoContentAsync } from './memoSlice';

import { useTranslation } from 'react-i18next';

import { useOidcFetch } from '@axa-fr/react-oidc';
import { UIMemoContentShape } from '../../../model/MemoModelShapes';

import { Editor } from '@tinymce/tinymce-react';
import { selectTinyMceLang } from '../../../appSlice';
import { MemoStatus } from '../../../common/MetaData';
import { delayUpdate } from '../../../common/MetaSetting';

export function MemoEdit(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetch } = useOidcFetch();

  const currentMemoContent: UIMemoContentShape | undefined = useAppSelector(selectCurrentMemoContent);
  const currentEditMemoContent: string | undefined = useAppSelector(selectCurrentEditMemoContent);
  const [memoId, setMemoId] = useState<string | undefined>(currentMemoContent?.guid);
  const [initialContent, setInitialContent] = useState<string | undefined>(currentEditMemoContent);

  useEffect(() => {
    setMemoId(currentMemoContent?.guid);
    if (memoId !== currentMemoContent?.guid) {
      setInitialContent(currentEditMemoContent);
    }
  }, [currentMemoContent]);

  const handleMemoContentChange = (newValue: string) => {
    dispatch(updateMemoContentAsync({ oidcFetch: fetch, memoId: currentMemoContent?.guid, memoContent: newValue }));
  };

  const markMemoContentChanged = () => {
    dispatch(updateMemoPropertiesAsync({ memoId: currentMemoContent?.guid, updateStatus: MemoStatus.NOTUPDATE }));
  };

  const editorRef = useRef<any>(null);
  const editorBoxRef = useRef<HTMLButtonElement>(null);

  const tinyMceLang = useAppSelector(selectTinyMceLang);

  let contentChangedTimer: any;
  let editorConfig = {
    skin: 'oxide',
    width: '100%',
    height: '100%',
    language: tinyMceLang,
    menubar: false,
    plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen', 'insertdatetime', 'media', 'table', 'preview', 'wordcount'],
    toolbar: 'undo redo | blocks | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
    paste_data_images: false,
    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    content_style: '',
    setup: function (editor: any) {
      // editor.on("Paste input Undo Redo Change", function (e: any) {
      editor.on('input change Undo Redo', function (e: any) {
        clearTimeout(contentChangedTimer);
        contentChangedTimer = setTimeout(handleMemoContentChange, delayUpdate, editorRef.current.getContent());
      });
      editor.on('keydown', (e: any) => {
        if (9 == e.keyCode) {
          editor.execCommand('Indent');
          e.preventDefault();
          return false;
        }
        return true;
      });
    },
    resize: false,
    branding: false,
    highlight_on_focus: false
  };

  return (
    <Box
      id={memoId}
      ref={editorBoxRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%',
        height: '100%'
      }}
    >
      <Editor
        licenseKey="gpl"
        key={memoId} // Important!!!
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialContent}
        init={editorConfig}
        onEditorChange={(newValue, editor) => {
          // setText(editor.getContent({format: 'text'}));
          markMemoContentChanged();
        }}
      />
    </Box>
  );
}
