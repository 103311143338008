import React, { useState, useEffect, useRef } from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Button,
  Avatar,
  ListItemAvatar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Typography,
  FormControl,
  ListItemSecondaryAction
} from '@mui/material';

import { MoreHoriz, PieChart, Star, Add, Delete, Edit, Share } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { updateUserGroupAsync, selectUserGroups, selectCurrentUserGroup, changeSelectedUserGroupId } from './userGroupSlice';

import { MessageType, UIUserGroupShape } from '../../../model/MemoModelShapes';
import { Alert, ConfirmationDialog } from '../../shared';
import getNewGUIDString from '../../../util/GUID';
import { findOneWMObjectFromAll, triggerEnterKeyFunction } from '../../../common/Utility';
import { emptyUserGroup, getEmptyUserGroup } from '../../../common/MetaData';
import { DEFAULT_SECTION_GUID, leftListWidth, maxLengthUserGroupName } from '../../../common/MetaSetting';
import { useTranslation } from 'react-i18next';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { updateGroupAsync } from '../group/groupSlice';
import { appendMessageAsync } from '../../../appSlice';

export function UserGroupList(props: any) {
  const userGroups = useAppSelector(selectUserGroups);
  const dispatch = useAppDispatch();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { fetch } = useOidcFetch();

  const [editingUserGroup, setEditingUserGroup] = useState<UIUserGroupShape>(emptyUserGroup);
  const currentUserGroup: any = useAppSelector(selectCurrentUserGroup);
  const [openEdit, setOpenEdit] = useState(false);

  const [editName, setEditName] = useState<string | undefined>('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [moreId, setMoreId] = useState<string | undefined>('');

  useEffect(() => {
    return () => {};
  }, []);

  const setCurrentEditingUserGroup = (userGroup: UIUserGroupShape) => {
    setEditingUserGroup(userGroup);
    setEditName(userGroup.name);
  };

  const handleCreateUserGroup = () => {
    let newUserGroup: UIUserGroupShape = Object.assign(getEmptyUserGroup(), {
      guid: getNewGUIDString(),
      name: t('defaultUserGroupName')
    });
    dispatch(updateUserGroupAsync(newUserGroup));
    setCurrentEditingUserGroup(newUserGroup);
    if (addButtonRef && addButtonRef.current) addButtonRef.current.blur();
  };

  const handleMoreOperationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    let tmpMoreId: string | null = event.currentTarget.getAttribute('data-item-guid');
    setMoreId(tmpMoreId ? tmpMoreId : '');

    let tmpEditName: string | null = event.currentTarget.getAttribute('data-item-user-group-name');
    setEditName(tmpEditName ? tmpEditName : '');
  };

  const handleUserGroupClick = (userGroupId: string) => () => {
    setMoreId(userGroupId);
    if (currentUserGroup && userGroupId === currentUserGroup.guid) return;

    if (userGroupId === DEFAULT_SECTION_GUID) {
      dispatch(changeSelectedUserGroupId(DEFAULT_SECTION_GUID));
      return;
    }

    let userGroup = findOneWMObjectFromAll(userGroups, userGroupId);
    if (userGroup) dispatch(changeSelectedUserGroupId(userGroup.guid));
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    let userGroup = findOneWMObjectFromAll(userGroups, moreId);
    if (userGroup) {
      setCurrentEditingUserGroup(Object.assign({}, userGroup));
      setOpenEdit(true);
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const isCurrentUserGroupChanged = () => {
    return !(editingUserGroup.name === editName);
  };

  const cleanEditingUserGroup = () => {
    setEditingUserGroup(emptyUserGroup);

    setOpenEdit(false);
    setEditName('');
  };

  const handleOnClose = () => {
    if (isCurrentUserGroupChanged()) {
      dispatch(appendMessageAsync({ messageType: MessageType.WARNING, message: t('warningMessageChangeNotSave') }));
      return;
    }

    setOpenEdit(false);
    cleanEditingUserGroup();
  };

  const handleEditUserGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(event.target.value);
  };

  const handleEditKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleEditSave);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    cleanEditingUserGroup();
  };

  const handleEditSave = () => {
    dispatch(
      updateUserGroupAsync({
        oidcFetch: fetch,
        userGroup: Object.assign(editingUserGroup, { name: editName })
      })
    );
    setOpenEdit(false);
    cleanEditingUserGroup();
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setMoreId('');
    setEditName('');
  };

  const handleConfirmDelete = (selectedId: string | undefined) => {
    if (selectedId) {
      let currentUserGroup = findOneWMObjectFromAll(userGroups, selectedId);
      if (currentUserGroup)
        dispatch(
          updateUserGroupAsync({
            oidcFetch: fetch,
            userGroup: Object.assign({}, currentUserGroup, { stateCode: 0 })
          })
        );
      setOpenConfirm(false);
      setMoreId('');
    } else {
      setOpenConfirm(false);
      setMoreId('');
    }
  };

  let listCount: number = 0;
  if (userGroups) listCount = userGroups.filter((userGroup: any) => userGroup.stateCode !== 0).length;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%'
      }}
    >
      <Box>
        <Box
          sx={{
            minWidth: 300,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              flexGrow: 1,
              fontWeight: 'bolder'
            }}
            component="span"
          >
            {t('titleUserGroups')} {'(' + listCount + ')'}
          </Typography>
          <IconButton color="primary" onClick={handleCreateUserGroup} ref={addButtonRef}>
            <Add />
          </IconButton>
        </Box>
        <Box className="children-content">
          <List sx={{ width: leftListWidth, bgcolor: 'background.paper' }} dense>
            {userGroups &&
              userGroups
                .filter((ug: any) => ug.stateCode !== 0)
                .map((uiUserGroup: UIUserGroupShape) => {
                  const labelId = `checkbox-list-label-${uiUserGroup.guid}`;
                  return (
                    <ListItem
                      key={uiUserGroup.guid}
                      sx={{
                        '&:hover .moreOperation': { visibility: 'inherit' }
                      }}
                      secondaryAction={
                        <IconButton
                          className="moreOperation"
                          sx={{ visibility: 'hidden' }}
                          data-item-guid={uiUserGroup.guid}
                          data-item-user-group-name={uiUserGroup.name}
                          edge="end"
                          onClick={handleMoreOperationClick}
                        >
                          <MoreHoriz />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        sx={{
                          padding: 0,
                          '&.Mui-selected': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main',
                            fontWeight: 'bolder'
                          },
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.main'
                          }
                        }}
                        role={undefined}
                        onClick={handleUserGroupClick(uiUserGroup.guid)}
                        selected={uiUserGroup.guid === moreId}
                      >
                        {/* <ListItemAvatar>
                          <Avatar variant="rounded">
                            <PieChart />
                          </Avatar>
                        </ListItemAvatar> */}

                        <ListItemAvatar
                          sx={{
                            minWidth: '0.2em'
                          }}
                        ></ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            className="moreOperation"
                            sx={{ visibility: 'hidden' }}
                            data-item-guid={uiUserGroup.guid}
                            data-item-user-group-name={uiUserGroup.name}
                            edge="end"
                            onClick={handleMoreOperationClick}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography sx={{ color: 'inherit', background: 'inherit', fontWeight: 'inherit' }} noWrap>
                              {uiUserGroup.name}
                            </Typography>
                          }
                          secondary={<React.Fragment>{new Date(uiUserGroup.modifyTimestamp).toLocaleDateString()}</React.Fragment>}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMoreMenu}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem dense onClick={handleEdit}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>{t("buttonEdit")}</ListItemText>
        </MenuItem>
        <Divider /> */}
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('buttonDelete')}</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={openEdit} onClose={handleOnClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('titleEditUserGroup')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{/* Update userGroup. */}</DialogContentText>
          <FormControl sx={{ p: 1, width: '100%' }} size="small">
            <TextField
              autoFocus
              id="editName"
              label={t('labelName')}
              type="text"
              fullWidth
              variant="standard"
              value={editName}
              inputProps={{ maxLength: maxLengthUserGroupName }}
              onChange={handleEditUserGroupNameChange}
              onKeyDown={handleEditKeyPress}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} variant="outlined" color="error">
            {t('buttonCancel')}
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        id="confirmDelete"
        keepMounted
        open={openConfirm}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        keyValue={moreId}
        titleValue={t('titleDeleteUserGroup')}
        messageValue={t('warningMessageDeleteConfirm') + editName}
      />
    </Box>
  );
}
