import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';

import { Footer } from './Footer';
import { Header } from './Header';
import { AppMain } from './AppMain';

import { drawerHeaderMinHeight } from '../../common/MetaSetting';

export const ALayout = () => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();

  // let headerHeight = mainMenuHeaderHeight;

  return (
    // <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "stretch" }}>
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        // height: `calc(100vh - ${headerHeight})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch'
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1, marginTop: drawerHeaderMinHeight }}>
        <AppMain>
          <Outlet />
        </AppMain>
      </Box>
      <Footer>
        {t('copyRight')} | {t('verifyInfo')}
      </Footer>
    </Box>
  );
};
