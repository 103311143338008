export const ROUTES = {
  other: '*',
  index: '/',
  view: '/view',
  memo: '/view/memo/:memoId',
  home: '/home',
  dashboard: '/home/dashboard',
  edit: '/home/edit',
  account: '/home/account',
  user_group: '/home/userGroup',
  training: '/home/training'
};

export const ROUTES_LIST = {
  memo: '/view/memo',
  edit: '/home/edit'
};

export const VIEW_ROUTES_LIST = {
  memo: '/share/memo'
};
