export const LOAD_USER = 'LOAD_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USER_MARKS = 'LOAD_USER_MARKS';
export const SEARCH_USERS_BY_KEY = 'SEARCH_USERS_BY_KEY';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER = 'UPDATE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SYNC_SECTIONS = 'SYNC_SECTIONS';
export const SYNC_SECTION_SHARES = 'SYNC_SECTION_SHARES';
export const SYNC_GROUPS = 'SYNC_GROUPS';
export const SYNC_GROUP_SHARES = 'SYNC_GROUP_SHARES';
export const SYNC_MEMOS = 'SYNC_MEMOS';
export const SYNC_FAILED_MEMOS = 'SYNC_FAILED_MEMOS';
export const SYNC_MEMO_SHARES = 'SYNC_MEMO_SHARES';
export const SYNC_MEMOS_BY_GROUP = 'SYNC_MEMOS_BY_GROUP';
export const SYNC_MEMO_SHARES_BY_GROUP = 'SYNC_MEMO_SHARES_BY_GROUP';
export const SYNC_TRANSFER_MEMOS_BY_GROUP = 'SYNC_TRANSFER_MEMOS_BY_GROUP';
export const SYNC_TRANSFER_GROUPS_BY_SECTION = 'SYNC_TRANSFER_GROUPS_BY_SECTION';
export const UPDATE_MEMO_TAG = 'UPDATE_MEMO_TAG';
export const UPDATE_MEMO_TRAINING = 'UPDATE_MEMO_TRAINING';
export const CREATE_MEMO = 'CREATE_MEMO';
export const UPDATE_MEMO = 'UPDATE_MEMO';
export const UPDATE_MEMO_CONTENT = 'UPDATE_MEMO_CONTENT';
export const REFRESH_MEMO = 'REFRESH_MEMO';
export const REFRESH_MEMO_CONTENT = 'REFRESH_MEMO_CONTENT';
export const UPDATE_MEMO_SHARE = 'UPDATE_MEMO_SHARE';
export const UPDATE_MEMO_PROPERTIES = 'UPDATE_MEMO_PROPERTIES';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_SECTION_SHARE = 'UPDATE_SECTION_SHARE';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SHARE = 'UPDATE_GROUP_SHARE';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';

export const VIEW_LOAD_MEMO = 'VIEW_LOAD_MEMO';
export const SEARCH_MEMOS_BY_KEY = 'SEARCH_MEMOS_BY_KEY';
export const UPDATE_SELECTED_PATH_BY_GROUP_ID = 'UPDATE_SELECTED_PATH_BY_GROUP_ID';

export const APPEND_MESSAGE = 'APPEND_MESSAGE';

export const SEND_TRAINING_MESSAGE = 'SEND_TRAINING_MESSAGE';
export const CREATE_PHRASE_ASYNC = 'CREATE_PHRASE_ASYNC';
