import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { fetchUserGroups, fetchUserGroup, remoteUserGroupSync } from './userGroupAPI';
import { FetchResponseShape, HttpResponseType, MessageType, UIUserGroupShape } from '../../../model/MemoModelShapes';

import { SYNC_SECTIONS, UPDATE_USER_GROUP } from '../../constants/ReduxConst';
import { defaultUserGroup, getEmptyUserGroup } from '../../../common/MetaData';
import { findOneWMObjectFromAll } from '../../../common/Utility';
import { appendMessageAsync } from '../../../appSlice';
import { retryInterval } from '../../../common/MetaSetting';

export interface UserGroupState {
  userGroups: UIUserGroupShape[];
  selectedUserGroupId: string;
  status: 'idle' | 'loading' | 'failed';
  // lastSyncTimestamp: number;
  isSyncing: boolean;
  currentUserGroup: UIUserGroupShape | undefined;
}

const initialState: UserGroupState = {
  userGroups: [],
  selectedUserGroupId: '',
  status: 'idle',
  // lastSyncTimestamp: 0,
  isSyncing: false,
  currentUserGroup: undefined
};

const getUserGroupsNeedSyncForLocal = (localUserGroupList: UIUserGroupShape[], remoteUserGroupList: UIUserGroupShape[]) => {
  var result: UIUserGroupShape[] = [];
  remoteUserGroupList.forEach((remoteUserGroup) => {
    var filteredItems = localUserGroupList.filter((s) => s.guid === remoteUserGroup.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteUserGroup.modifyTimestamp)) {
      result.push(remoteUserGroup);
    }
  });
  // console.log("UserGroup need update for local", result);
  return result;
};

const getUserGroupsNeedSyncForRemote = (localUserGroupList: UIUserGroupShape[], remoteUserGroupList: UIUserGroupShape[]) => {
  var result: UIUserGroupShape[] = [];
  localUserGroupList.forEach((localUserGroup) => {
    if (localUserGroup.stateCode === 0) return;
    var filteredItems = remoteUserGroupList.filter((s) => s.guid === localUserGroup.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < localUserGroup.modifyTimestamp)) {
      result.push(localUserGroup);
    }
  });
  // console.log("UserGroup need update for remote", result);
  return result;
};

const remoteUserGroupSyncRetry = (oidcFetch: Function, userGroupId: string, thunkAPI: any) => {
  let userGroupLocal = findOneWMObjectFromAll(thunkAPI.getState().userGroup.userGroups, userGroupId);
  remoteUserGroupSync(oidcFetch, userGroupLocal).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupResponse.content.then((content: any) => {
        if (content.syncResult === -1 && content.syncedUserGroup) thunkAPI.dispatch(saveUserGroupToLocal(content.syncedUserGroup));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => remoteUserGroupSyncRetry(oidcFetch, userGroupId, thunkAPI), retryInterval);
    }
  });
};

const fetchUserGroupRetry = (oidcFetch: Function, userGroupId: string, thunkAPI: any) => {
  fetchUserGroup(oidcFetch, userGroupId).then((userGroupResponse: FetchResponseShape) => {
    if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupResponse.content.then((content: any) => thunkAPI.dispatch(saveUserGroupToLocal(content)));
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      setTimeout(() => fetchUserGroupRetry(oidcFetch, userGroupId, thunkAPI), retryInterval);
    }
  });
};

const syncAllUserGroupsRetry = (oidcFetch: Function, thunkAPI: any) => {
  let userGroups = [...thunkAPI.getState().userGroup.userGroups];
  fetchUserGroups(oidcFetch, 0).then((userGroupsResponse: FetchResponseShape) => {
    if (userGroupsResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupsResponse.content.then((remoteUserGroups: any) => {
        var userGroupsLocalNeedSync = getUserGroupsNeedSyncForLocal(userGroups, remoteUserGroups);
        userGroupsLocalNeedSync.forEach((userGroupLocal) => {
          fetchUserGroupRetry(oidcFetch, userGroupLocal.guid, thunkAPI);
        });

        var userGroupsRemoteNeedSync = getUserGroupsNeedSyncForRemote(userGroups, remoteUserGroups);
        userGroupsRemoteNeedSync.forEach((userGroupRemote) => {
          remoteUserGroupSyncRetry(oidcFetch, userGroupRemote.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupsResponse.message }));
      setTimeout(() => syncAllUserGroupsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchUserGroupsRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchUserGroups(oidcFetch, 0).then((userGroupsResponse: FetchResponseShape) => {
    if (userGroupsResponse.responseType === HttpResponseType.SUCCESS) {
      userGroupsResponse.content.then((remoteUserGroups: any) => {
        remoteUserGroups.forEach((remoteUserGroup: any) => {
          fetchUserGroupRetry(oidcFetch, remoteUserGroup.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupsResponse.message }));
      setTimeout(() => fetchUserGroupsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllUserGroupsAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_SECTIONS, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchUserGroupsRetry(oidcFetch, thunkAPI);

  return currentTime;
});

export const updateUserGroupAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_USER_GROUP, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let userGroupLocal = Object.assign({}, param.userGroup, {
    modifyTimestamp: currentTime
  });
  if (userGroupLocal.createTimestamp === 0) userGroupLocal.createTimestamp = currentTime;
  thunkAPI.dispatch(saveUserGroupToLocal(userGroupLocal));
  remoteUserGroupSyncRetry(param.oidcFetch, userGroupLocal.guid, thunkAPI);

  return currentTime;
});

export const userGroupSlice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    changeSelectedUserGroupId: (state, action: PayloadAction<string>) => {
      if (state.selectedUserGroupId !== action.payload) {
        state.selectedUserGroupId = action.payload;
        let userGroup = findOneWMObjectFromAll(state.userGroups, action.payload);
        if (userGroup) {
          state.currentUserGroup = Object.assign({}, userGroup);
        } else {
          state.currentUserGroup = undefined;
        }
      }
    },
    saveUserGroupToLocal: (state, action: PayloadAction<UIUserGroupShape>) => {
      var filteredItems = state.userGroups.filter((s) => s.guid !== action.payload.guid);
      var updatedAddedUserGroups = [...filteredItems, Object.assign({}, action.payload)];
      state.userGroups = updatedAddedUserGroups.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserGroupsAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllUserGroupsAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllUserGroupsAsync.rejected, (state) => {
        console.log('syncUserGroupAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const { changeSelectedUserGroupId, saveUserGroupToLocal } = userGroupSlice.actions;

export const selectUserGroups = (state: RootState) => state.userGroup.userGroups;
export const selectCurrentUserGroup = (state: RootState) => state.userGroup.currentUserGroup;

export default userGroupSlice.reducer;
