import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router';

import { useTheme, Box, Typography } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { fetchViewMemoAsync, selectCurrentViewMemo, selectCurrentViewMemoStatus } from './memoViewSlice';
import { useTranslation } from 'react-i18next';
import { VIEW_MEMO_STATUS_MARK } from '../../../common/MetaData';
import { Star, StarBorder } from '@mui/icons-material';
import { selectCurrentLanguage } from '../../../appSlice';
import { selectCurrentUser } from '../user/userSlice';
import { editTopStatusHeight, mainContentMarginTop } from '../../../common/MetaSetting';
import { useOidcFetch } from '@axa-fr/react-oidc';

export function MemoView(props: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const currentUser = useAppSelector(selectCurrentUser);

  const currentViewMemoStatus = useAppSelector(selectCurrentViewMemoStatus);
  const currentViewMemo = useAppSelector(selectCurrentViewMemo);
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const { fetch } = useOidcFetch();

  let headerFooterHeight = mainContentMarginTop;

  const params: any = useParams();
  const triggerSyncTasks = () => {
    dispatch(fetchViewMemoAsync({ oidcFetch: fetch, memoId: params.memoId }));
  };

  useEffect(() => {
    triggerSyncTasks();
  }, []);

  const updateErrorMessage = () => {
    switch (currentViewMemoStatus) {
      case VIEW_MEMO_STATUS_MARK.TARGET_NOT_FOUND:
        setErrorMessage(t('viewMemoNotFound'));
        break;
      case VIEW_MEMO_STATUS_MARK.NOT_AUTH:
        setErrorMessage(t('viewMemoNoAuthMessage'));
        break;
      default:
        setErrorMessage(undefined);
        break;
    }
  };

  useEffect(() => {
    updateErrorMessage();
  }, [currentViewMemoStatus, currentLanguage]);

  if (!currentViewMemo) return <Box></Box>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '100vw',
        height: `calc(100vh - ${headerFooterHeight})`,
        backgroundColor: 'secondary.light'
      }}
    >
      {errorMessage ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1em',
            // minWidth: '512px',
              maxWidth: '1280px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '1200px' },
            backgroundColor: 'background.paper'
          }}
        >
          <Typography>{errorMessage}</Typography>
        </Box>
      ) : (
        currentViewMemo && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: "space-between",
              alignItems: 'stretch',
              // minWidth: '512px',
              // maxWidth: '1280px',
              // width: '60%',
              maxWidth: '1280px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '1200px' },
              backgroundColor: 'background.paper'
            }}
          >
            <Box sx={{ flexGrow: 0, padding: '5px' }} className="memo-title">
              {currentViewMemo?.title}
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                height: editTopStatusHeight,
                backgroundColor: 'secondary.light',
                paddingLeft: '5px',
                paddingRight: '5px'
              }}
            >
              <Box className="memo-subtitle">{new Date(currentViewMemo?.modifyTimestamp).toLocaleString()}</Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {currentUser?.userId !== currentViewMemo?.authorId && <StarBorder></StarBorder>}
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }}>
              <div style={{ padding: '1em' }} dangerouslySetInnerHTML={{ __html: currentViewMemo?.content }} />
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}
