import React from 'react';

import { Box, Typography, Breadcrumbs, Link } from '@mui/material';

import { Star, PieChart, GroupWork } from '@mui/icons-material';

import { SectionList } from '../components/section/SectionList';

import { DEFAULT_GROUP_GUID, DEFAULT_SECTION_GUID, leftListWidth } from '../../common/MetaSetting';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { UIShareShape, UIUserGroupShape } from '../../model/MemoModelShapes';
import { UserGroupList } from '../components/UserGroup/UserGroupList';
import { UserGroupPanel } from '../components/UserGroup/UserGroupPanel';
import { selectCurrentUserGroup, changeSelectedUserGroupId, updateUserGroupAsync } from '../components/UserGroup/userGroupSlice';
import { OidcSecure, useOidcFetch } from '@axa-fr/react-oidc';
import { lightGreen } from '@mui/material/colors';

const UserGroupPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fetch } = useOidcFetch();

  const currentUserGroup = useAppSelector(selectCurrentUserGroup);

  const handlePathStartClick = () => {
    // dispatch(changeSelectedUserGroupId(""));
  };

  const handleUserGroupSave = (userGroup: UIUserGroupShape) => {
    console.log('userGroup', userGroup);
    dispatch(updateUserGroupAsync({ oidcFetch: fetch, userGroup: userGroup }));
    dispatch(changeSelectedUserGroupId(userGroup.guid));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch'
      }}
    >
      <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch', backgroundColor: 'background.paper', padding: '0.2em' }}>
        <Breadcrumbs
          maxItems={3}
          sx={{
            flexGrow: 0,
            width: leftListWidth,
            overflowWrap: 'break-word'
          }}
        >
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary" component="span">
            <Link underline="none" sx={{ display: 'flex', alignItems: 'center' }} href="#" onClick={handlePathStartClick}>
              {t('memoPathStart')}
            </Link>
          </Typography>
          {/* {currentUserGroup && (
            <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary" component="span">
              {currentUserGroup.guid === DEFAULT_SECTION_GUID ? <Star sx={{ mr: 0.5 }} fontSize="inherit" /> : <PieChart sx={{ mr: 0.5 }} fontSize="inherit" />}
              {currentUserGroup.guid === DEFAULT_SECTION_GUID ? t("titleDefaultSection") : currentUserGroup.name}
            </Typography>
          )} */}
        </Breadcrumbs>
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 300,
            maxWidth: 350
          }}
        >
          <UserGroupList />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'stretch'
        }}
      >
        {currentUserGroup && <UserGroupPanel onUserGroupSave={handleUserGroupSave} />}
      </Box>
    </Box>
  );
};

export const SecureUserGroupPage = () => (
  <OidcSecure>
    <UserGroupPage />
  </OidcSecure>
);

export default SecureUserGroupPage;
