import { SOURCE_ID, userServerAddress } from '../../../common/MetaSetting';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';

export function uploadAvatar(oidcFetch: Function, file: File) {
  let formData = new FormData();
  formData.append('file', file);
  var myInit = {
    method: 'POST',
    body: formData
  };

  return oidcFetch(`${userServerAddress}/file/single/${SOURCE_ID}/avatar`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
