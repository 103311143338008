import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';

import { ArrowCircleUp } from '@mui/icons-material';
import { uploadAvatar } from './uploadAPI';

import { useAppDispatch } from '../../../app/hooks';
import { fetchUserAsync } from '../user/userSlice';
import { useTranslation } from 'react-i18next';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { FetchResponseShape, HttpResponseType, MessageType } from '../../../model/MemoModelShapes';
import { appendMessageAsync } from '../../../appSlice';
import { retryInterval } from '../../../common/MetaSetting';

const UploadAvatar = () => {
  const [uploadDisable, setUploadDisable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const uploadClick = () => {
    if (uploadDisable) return;

    setUploadDisable(true);
  };

  const { fetch } = useOidcFetch();

  const uploadAvatarRetry = (oidcFetch: Function, file: any) => {
    uploadAvatar(oidcFetch, file).then((userGroupResponse: FetchResponseShape) => {
      if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
        dispatch(appendMessageAsync({ messageType: MessageType.SUCCESS, message: 'messageIdUpdateSuccess' }));
      } else {
        dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
        setTimeout(() => uploadAvatarRetry(oidcFetch, file), retryInterval);
      }
    });
  };

  const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    for (let i = 0; i < files.length; i++) {
      uploadAvatarRetry(fetch, files[i]);

      dispatch(fetchUserAsync(fetch));
      setUploadDisable(false);
      break;
    }
  };

  return (
    <Button variant="contained" component="label" color="primary" onClick={uploadClick} disabled={uploadDisable}>
      <ArrowCircleUp /> {t('buttonUpload')}
      <input type="file" hidden onChange={fileChange} />
    </Button>
  );
};

export default UploadAvatar;
