import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  keyValue: string | undefined;
  titleValue: string;
  messageValue: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (value: string | undefined) => void;
}

export function ConfirmationDialog(props: ConfirmationDialogRawProps) {
  const { t } = useTranslation();
  const { onClose, onConfirm, keyValue, titleValue, messageValue, open, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm(keyValue);
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} {...other}>
      <DialogTitle>{titleValue}</DialogTitle>
      <DialogContent dividers>
        <Typography component="span">{messageValue}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="outlined" color="primary">
          {t('buttonCancel')}
        </Button>
        <Button onClick={handleOk} variant="contained" color="error">
          {t('buttonConfirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
