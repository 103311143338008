import React from 'react';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import Account from './Account';

export function ViewList(props: any) {
  const { t } = useTranslation();

  let { sources } = props;

  let source = sources[0];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'start',
        bgcolor: 'secondary.light'
      }}
    >
      {source.VALUES.filter((sourceObj: any) => sourceObj.nodeIdentity === '34').map((sourceObj: any, index: number) => {
        return <Account key={index} indexKey={index} account={sourceObj}></Account>;
      })}
    </Box>
  );
}
