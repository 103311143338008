import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const NotFound = () => {
  return (
    <Box>
      <Box
        sx={{
          height: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>Page Not Found</Typography>
      </Box>
    </Box>
    // {!isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={() => login('/profile')}>Login</button></p>}
    // {/* {isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={() => logout('/profile')}>logout /profile</button></p>} */}
    // {/* {isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={() => getUsers()}>Get Users</button></p>} */}
    // {/* {isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={() => logout()}>logout</button></p>} */}
    // {/* {isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={() => logout(null)}>logout whithout callbackredirect</button></p>} */}
    // {isAuthenticated && <p><button type="button" className="btn btn-primary" onClick={async () => console.log('renewTokens result', await renewTokens())}>renew tokens</button></p>}
  );
};

export default NotFound;
