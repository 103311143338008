import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import lodash from 'lodash';

function subscribeCallBack(data, subscribes) {
    if (data) {
        let topic = data.headers.destination;
        console.log('subscribeCallBack', topic);
        let funces = subscribes.get(topic);
        funces.forEach((func) => {
            func(data);
        });
    }
}

let clientManager = {
    client: null,
    connecting: false,
    subscribes: new Map(),
    subscribe(topic, onMessage) {
        if (this.client != null && this.client.connected == true) {
            console.log('Add subscribe - connected', topic);
            if (!this.subscribes.has(topic)) {
                this.client.subscribe(topic, (data) =>
                    subscribeCallBack(data, this.subscribes)
                );
                this.subscribes.set(topic, [onMessage]);
            } else {
                let funces = this.subscribes.get(topic);
                funces.push(onMessage);
            }
        } else {
            console.log('Add subscribe - disconnected');
            if (!this.subscribes.has(topic)) {
                this.subscribes.set(topic, [onMessage]);
            } else {
                let funces = this.subscribes.get(topic);
                funces.push(onMessage);
            }
        }
    },
    subscribesAll() {
        console.log('Subscribe all after connected/reconnected');
        if (lodash.isEmpty(this.client) || this.client.connected != true) {
            return;
        }
        let subscribes = this.subscribes;
        for (let topic of subscribes.keys()) {
            this.client.subscribe(topic, (data) =>
                subscribeCallBack(data, subscribes)
            );
        }
    },
    disconnect() {
        console.log('Disconnected');
        if (lodash.isEmpty(this.client) || this.client.connected != true) {
            return;
        }
        this.client.disconnect();
        this.subscribes = new Map();
    },
    connect(wsUrl, accessToken, onSuccess, onDisconnect) {
        try {
            if (this.connecting == true) {
                console.log('Connection is processing now');
                return;
            }
            if (!accessToken)
                return;
            this.connecting = true;

            if (lodash.isEmpty(this.client) || this.client.connected != true) {
                let socket = new SockJS(`${wsUrl}?access_token=${accessToken}`, null, {
                    timeout: 6000
                });
                let stompClient = Stomp.over(socket);
                stompClient.debug = null;
                console.log('Start to connect');
                stompClient.connect(
                    {
                        Authorization: accessToken,
                    },
                    () => {
                        this.client = stompClient;
                        console.log('Connection success');
                        this.subscribesAll();
                        if (onSuccess != null && onSuccess != undefined) {
                            onSuccess();
                        }
                    },
                    (error) => this.errorCallBack(wsUrl, accessToken, error, onSuccess, onDisconnect)
                );
            } else if (this.client != null && this.client.connected == true) {
                onSuccess();
            }
        } catch (err) {
            console.log('Connection error', err);
        } finally {
            this.connecting = false;
        }
    },
    errorCallBack(wsUrl, accessToken, error, onSuccess, onDisconnect) {
        console.log('Connection failed', error);
        if (onDisconnect != null && onDisconnect != undefined) {
            onDisconnect();
        }
        setTimeout(() => {
            console.log('Reconnecting');
            this.connect(wsUrl, accessToken, onSuccess, onDisconnect);
        }, 10000);
    },
    // sendMessage(path, messageObject) {
    //     if (lodash.isEmpty(this.client) || this.client.connected != true) {
    //         return false;
    //     }

    //     try {
    //         this.client.send(path, {}, JSON.stringify(messageObject));
    //     } catch (err) {
    //         console.log('Send message failed', err);
    //         return false;
    //     }
    //     return true;
    // }
};

let socketManager = undefined;



export const getSocketManager = () => {
    if (!socketManager)
        socketManager = clientManager;

    return socketManager;
};

