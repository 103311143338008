import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/store';

import { UIShareShape } from '../model/MemoModelShapes';

export interface ShareState {
  currentShare: UIShareShape | undefined;
}

const initialState: ShareState = {
  currentShare: undefined
};

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    changeCurrentShare: (state, action: PayloadAction<UIShareShape | undefined>) => {
      state.currentShare = Object.assign({}, action.payload);
    }
  },
  extraReducers: (builder) => {}
});

export const { changeCurrentShare } = shareSlice.actions;

export const selectCurrentShare = (state: RootState) => state.share.currentShare;

export default shareSlice.reducer;
