import React, { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Training } from './Training';
import { mainContentMarginTopThird } from '../../../common/MetaSetting';
import { Pattern } from './Pattern';

export function Trainings(props: any) {
  const { t } = useTranslation();

  let { trainings } = props;

  console.log('trainings', trainings);
  useEffect(() => { }, [trainings]);

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${mainContentMarginTopThird})`,
        padding: '0.5em',
        overflowY: 'auto'
      }}
    >
      {trainings.sections &&
        trainings.sections.length > 0 &&
        trainings.sections.map((training: any, index: any) => {
          return <Training key={index} training={training} />;
        })}
      {trainings.results &&
        trainings.results.length > 0 &&
        trainings.results.map((result: any, index: any) => {
          return <Pattern key={index} pattern={result} />;
        })}
    </Box>
  );
}
