const CUTTING_EXPRESSION = /\s+[^\s]*$/;

export const createShortcutString = (text: string, limit: number) => {
  if (text.length > limit) {
    const part = text.slice(0, limit - 3);
    if (part.match(CUTTING_EXPRESSION)) {
      return part.replace(CUTTING_EXPRESSION, ' ...');
    }
    return part + '...';
  }
  return text;
};

export const compareStringArrayWithoutOrder = (sourceStrings: string[], targetStrings: string[]) => {
  if (sourceStrings.length === targetStrings.length) {
    if (sourceStrings.length === 0) return true;

    let isStringMissing = false;
    sourceStrings.map((sr) => {
      if (targetStrings.findIndex((tr) => tr === sr) < 0) isStringMissing = true;
    });
    return !isStringMissing;
  } else {
    return false;
  }
};

export const isEmptyOrSpaces = (str: string) => {
  return str === null || str.match(/^ *$/) !== null;
};

export const findOneWMObjectFromAll = (list: any[], targetId: string | undefined) => {
  return list.filter((t: any) => t.guid === targetId)[0];
};

export const findOneTargetWithGUID = (list: any[], targetId: string | undefined) => {
  return list.filter((t: any) => t.guid === targetId)[0];
};

export const constructSectionGroupTree = (levelList1: any[], levelList2: any[]) => {
  if (!levelList1 || levelList1.length < 1) return undefined;

  if (!levelList2 || levelList2.length < 1) return levelList1;

  let result: any[] = [];
  levelList1
    .filter((l1i) => l1i.stateCode !== 0)
    .forEach((l1i) => {
      result.push({
        guid: l1i.guid,
        name: l1i.name,
        groups: levelList2
          .filter((l2i) => l2i.sectionId === l1i.guid && l2i.stateCode !== 0)
          .map((l2i) => {
            return { guid: l2i.guid, name: l2i.name };
          })
      });
    });

  return result;
};

export const constructSectionGroup = (levelList1: any[], levelList2: any[]) => {
  if (!levelList1 || levelList1.length < 1) return [];

  if (!levelList2 || levelList2.length < 1) return levelList1;

  return levelList1
    .filter((l1i) => l1i.stateCode !== 0)
    .map((l1i) => {
      let section = levelList2.filter((l2i) => l2i.guid === l1i.sectionId)[0];
      let sectionName = 'Default';
      if (section) sectionName = section.name;

      return { key: l1i.guid, name: l1i.name, sectionName: sectionName };
    });
};

export const triggerKeyFunction = (event: React.KeyboardEvent<HTMLInputElement>, key: string, onTrigger: any) => {
  if (event.key === key) {
    onTrigger();
  }
};

export const triggerEnterKeyFunction = (event: React.KeyboardEvent<HTMLInputElement>, onTrigger: any) => {
  triggerKeyFunction(event, 'Enter', onTrigger);
};

export const getObjectsNeedSyncForLocal = (localMObjectList: any[], remoteMObjectList: any[]) => {
  var result: any[] = [];
  remoteMObjectList.forEach((remoteMObject) => {
    var filteredItems = localMObjectList.filter((o) => o.guid === remoteMObject.guid);
    if (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteMObject.modifyTimestamp) {
      result.push(remoteMObject);
    }
  });
  return result;
};

export const getObjectsNeedSyncForRemote = (localMObjectList: any[], remoteMObjectList: any[]) => {
  var result: any[] = [];
  localMObjectList.forEach((localMObject) => {
    if (localMObject.isNew && localMObject.stateCode === 0) return;
    var filteredItems = remoteMObjectList.filter((o) => o.guid === localMObject.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < localMObject.modifyTimestamp)) {
      result.push(localMObject);
    }
  });
  return result;
};

export const getWMObjectsNeedSyncForSource = (sourceList: any[], targetList: any[]) => {
  var result: any[] = [];
  targetList.forEach((remoteMObject) => {
    var filteredItems = sourceList.filter((o) => o.guid === remoteMObject.guid);
    if (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteMObject.modifyTimestamp) {
      result.push(remoteMObject);
    } else if (!filteredItems || filteredItems.length < 1) {
      result.push(remoteMObject);
    }
  });
  return result;
};
