import React, { useEffect, useRef } from 'react';

import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

export function Training(props: any) {
  const { t } = useTranslation();

  let { training } = props;
  const [open, setOpen] = React.useState(false);

  console.log('training', training);
  useEffect(() => {}, [training]);

  const constructMarks = (size: any) => {
    let marks = [];

    for (let i = 0; i < size; i++) {
      marks.push(
        <TableCell key={i} style={{ backgroundColor: 'lightgray', textAlign: 'center' }}>
          {i + 1}
        </TableCell>
      );
    }
    return marks;
  };

  const constructNode = (node: any) => {
    return (
      <span>
        <p>
          {t('labelId')}: {node.nodeIdentity}
        </p>
        <p>
          {t('labelDescription')}: {node.description}
        </p>
        {node.wordProperties && (
          <p>
            {t('labelPart')}: {node.wordProperties}
          </p>
        )}
      </span>
    );
  };

  const constructNodes = (nodes: any[]) => {
    let nodesTd = [];
    for (let i = 0; i < nodes.length; i++) {
      let span = nodes[i].end - nodes[i].start;
      let tooltipContent = constructNode(nodes[i]);
      nodesTd.push(
        <Tooltip
          key={i}
          title={tooltipContent}
          arrow
          placement="top"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
        >
          <TableCell
            key={i}
            colSpan={span}
            style={{ backgroundColor: nodes[i].nodeIdentity === 0 || nodes[i].nodeIdentity === 308626 ? 'yellow' : 'lightblue', textAlign: 'center', border: '1px solid lightgray' }}
          >
            {nodes[i].label}
          </TableCell>
        </Tooltip>
      );
    }
    return nodesTd;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        padding: '0.2em'
      }}
    >
      {/* {training && <div>{training.content}</div>} */}
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell width={'1em'}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>
              <TableCell scope="row" align="left">
                <b>{training.content}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {training && training.sequences && training.sequences.length > 0 && (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width={'1em'}></TableCell>
                          {constructMarks(training.size)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {training.sequences.map((sequence: any, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{sequence.index}</TableCell>
                              {constructNodes(sequence.nodes)}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
