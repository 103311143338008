import React, { useEffect, useState } from 'react';

import { useOidc } from '@axa-fr/react-oidc';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

import { IconButton, Menu, Avatar, Tooltip, MenuItem, ToggleButtonGroup, ToggleButton, Typography, Toolbar, Box, Button, BadgeProps, Badge } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCurrentLanguage, changeCurrentLanguage } from '../../appSlice';
import { selectCurrentUser } from '../components/user/userSlice';
import { DEFAULT_LANGUAGE, hostServerAddress, userServerAddress, mainHeaderHeight, toolBarPaddingLeftRight } from '../../common/MetaSetting';

import { changeSelectedMemoId } from '../components/memo/memoSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';

const headerHeight = mainHeaderHeight;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export const Header = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { isAuthenticated, logout } = useOidc();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const currentUser = useAppSelector(selectCurrentUser);

  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const [currentLang, setCurrentLang] = useState(currentLanguage);
  const [isAuth, setIsAuth] = useState(isAuthenticated);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout(hostServerAddress);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    setCurrentLang(newAlignment);
    dispatch(changeCurrentLanguage(newAlignment));
    i18n.changeLanguage(newAlignment === 'zhCN' ? 'zh-CN' : 'en-US');
    dispatch(changeSelectedMemoId(''));
  };

  let mainTitle = t('appTitle');

  return (
    <AppBar
      position="fixed"
      sx={{
        flexGrow: 0,
        backgroundColor: 'secondary.light',
        color: 'primary.main'
      }}
    >
      <Toolbar variant="dense" sx={{ margin: 0, paddingLeft: toolBarPaddingLeftRight as any, paddingRight: toolBarPaddingLeftRight as any }}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'secondary.main',
            height: `${headerHeight}`,
            padding: '5px',
            borderRadius: '0.2em',
            bgcolor: 'background.paper'
          }}
        >
          <Avatar variant="square" src="/logo192.png" />
          {currentLang === DEFAULT_LANGUAGE ? (
            <Box>
              <span style={{ color: theme.palette.primary.main }}>
                {t('wordPrefixWise')}
                {t('wordLeftWise')}
              </span>
              <span style={{ color: theme.palette.secondary.main }}>
                {t('wordPrefixMemo')}
                {t('wordLeftMemo')}
              </span>
            </Box>
          ) : (
            <Box>
              <span style={{ color: theme.palette.primary.main }}>{t('wordPrefixWise')}</span>
              <span style={{ color: theme.palette.secondary.main }}>{t('wordLeftWise')}</span>
              <span style={{ color: theme.palette.primary.main }}>{t('wordPrefixMemo')}</span>
              <span style={{ color: theme.palette.secondary.main }}>{t('wordLeftMemo')}</span>
            </Box>
          )}
        </Box>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main'
          }}
        >
          {/* {mainTitle} */}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            flexGrow: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'end',
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1)
            }}
          >
            <Box sx={{ fontSize: '1em' }}>{currentUser?.userName}</Box>
            <ToggleButtonGroup value={currentLanguage} exclusive onChange={handleLanguageChange} size="small" color="primary">
              <ToggleButton
                value="zhCN"
                sx={{
                  height: '1.5em',
                  color: 'secondary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main',
                    fontWeight: 'bolder'
                  },
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main'
                  }
                }}
              >
                {t('langZh')}
              </ToggleButton>
              <ToggleButton
                value="enUS"
                sx={{
                  height: '1.5em',
                  color: 'secondary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main'
                  },
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'primary.main'
                  }
                }}
              >
                {t('langEn')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {isAuth ? (
            <Box>
              <Tooltip title={t('tipClickToOpenUserMenu')}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={currentUser?.userName}
                    sx={{ bgcolor: 'background.paper' }}
                    variant="rounded"
                    src={currentUser && currentUser.avatarPath && `${userServerAddress}${currentUser?.avatarPath}`}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: { headerHeight } }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem dense key="logoutItem" onClick={handleLogout}>
                  <Typography textAlign="center" component="span">
                    {t('buttonLogout')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box>
              <Button variant="outlined" size="small" sx={{ margin: 0.5 }}>
                {t('buttonRegister')}
              </Button>
              <Button variant="contained" size="small" sx={{ margin: 0.5 }} onClick={() => navigate(ROUTES.dashboard)}>
                {t('buttonLogin')}
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
