import React, { useState, useEffect } from 'react';

import { Box, TextField, Badge, BadgeProps, styled, Autocomplete, FormControlLabel, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, Button, Chip, Divider } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { useTranslation } from 'react-i18next';

import { ExpandMore, Group, Person, Public } from '@mui/icons-material';
import { FetchResponseShape, HttpResponseType, MessageType, UIShareShape, UIShareTargetShape } from '../../model/MemoModelShapes';
import { fetchUsersByKey } from './user/userAPI';
import { ChipArray } from '../shared/ChipArray';
import isArraiesEqualByGUID from '../../util/UtilFunction';
import { selectCurrentShare } from '../shareSlice';
import { triggerEnterKeyFunction } from '../../common/Utility';
import { selectUserGroups } from './UserGroup/userGroupSlice';
import { fetchUserMarksAsync, selectUserMarks } from './user/userSlice';
import { SHARE_TYPE_MARK } from '../../common/MetaData';
import { useOidcFetch } from '@axa-fr/react-oidc';
import { appendMessageAsync } from '../../appSlice';
import { editTopStatusHeight } from '../../common/MetaSetting';
import { StyledBadge } from '../shared/ShareMarker';
import { size } from 'lodash';

export function SharePanel(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onShareClose, onShareSave, keyValue, titleValue, open, ...other } = props;

  const currentShare = useAppSelector(selectCurrentShare);
  const userGroups: any[] = useAppSelector(selectUserGroups);
  const userMarks: any[] = useAppSelector(selectUserMarks);
  const [typeTitle, setTypeTitle] = useState('');
  const [shareTitle, setShareTitle] = useState(currentShare?.title);
  const [statusIsPublic, setStatusIsPublic] = useState(currentShare?.isPublic);
  const [userGroupIds, setUserGroupIds] = useState(currentShare?.targetUserGroupIds);
  const [userIds, setUserIds] = useState(currentShare?.targetUserIds);

  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [userGroupOptions, setUserGroupOptions] = useState<any>([]);
  // const [userKeyString, setUserKeyString] = useState("");
  const [userMark, setUserMark] = useState<any>();
  const [legacyUserKeyString, setLegacyUserKeyString] = useState('');

  let contentChangedTimer: any;

  const { fetch } = useOidcFetch();

  const handleUserKeyStringChange = (key: string) => {
    clearTimeout(contentChangedTimer);
    setLegacyUserKeyString(key);
    fetchUsersByKey(fetch, key).then((userGroupResponse: FetchResponseShape) => {
      if (userGroupResponse.responseType === HttpResponseType.SUCCESS) {
        userGroupResponse.content.then((remoteUsers: any) => {
          var newOptions: any[] = [];
          if (remoteUsers)
            remoteUsers.map((remoteUser: any) =>
              newOptions.push({
                id: remoteUser?.userId,
                label: remoteUser?.userName
              })
            );
          setUserOptions(newOptions);
        });
      } else {
        dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: userGroupResponse.message }));
      }
    });
  };

  const changeOptionBaseOnValue = (value: string) => {
    var searchKey = value.trim();
    // setUserKeyString(searchKey);

    clearTimeout(contentChangedTimer);
    if (searchKey) {
      if (searchKey !== legacyUserKeyString || searchKey.length < legacyUserKeyString.length) {
        contentChangedTimer = setTimeout(() => handleUserKeyStringChange(searchKey), 1000);
      }
    }
  };

  const getOptionsWithLabelByUserIds = (sourceUserIds: any[] | undefined) => {
    var updatedUserMark: any = [];
    if (sourceUserIds && sourceUserIds.length > 0) {
      userMarks.map((userMark) => {
        sourceUserIds
          .filter((u) => userMark.userId === u.id)
          .map((u) =>
            updatedUserMark.push({
              id: userMark?.userId,
              label: userMark?.userName
            })
          );
      });
    }
    return updatedUserMark;
  };

  const updateUserLists = (sourceUserIds: any[] | undefined) => {
    if (sourceUserIds) {
      var noneUserNameIds: any[] = [];
      sourceUserIds.filter((u) => !u.label)?.map((u) => noneUserNameIds.push(u.id));
      if (noneUserNameIds && noneUserNameIds.length > 0) {
        var optionsWithLabel: any[] = getOptionsWithLabelByUserIds(sourceUserIds);
        if (!optionsWithLabel || optionsWithLabel.length === 0) {
          dispatch(fetchUserMarksAsync({ oidcFetch: fetch, userIds: noneUserNameIds }));
        } else {
          noneUserNameIds = [];
          optionsWithLabel.filter((um) => !um.label).map((u) => noneUserNameIds.push(u.id));
          if (noneUserNameIds && noneUserNameIds.length > 0) {
            dispatch(fetchUserMarksAsync({ oidcFetch: fetch, userIds: noneUserNameIds }));
          } else {
            setUserIds(optionsWithLabel);
          }
        }
      } else {
        setUserIds(sourceUserIds);
      }
    }
  };

  useEffect(() => {
    updateUserLists(userIds);
  }, [userMarks]);

  useEffect(() => {
    var newOptions: any = [];
    if (userGroups) userGroups.map((userGroup) => newOptions.push({ id: userGroup?.guid, label: userGroup?.name }));

    setUserGroupOptions(newOptions);
  }, [userGroups]);

  useEffect(() => {
    switch (currentShare?.typeMark) {
      case SHARE_TYPE_MARK.SECTION:
        setTypeTitle(t('shareTitleSection'));
        break;
      case SHARE_TYPE_MARK.GROUP:
        setTypeTitle(t('shareTitleGroup'));
        break;
      case SHARE_TYPE_MARK.MEMO:
        setTypeTitle(t('shareTitleMemo'));
        break;
    }
    setShareTitle(currentShare?.title);
    setStatusIsPublic(currentShare?.isPublic);
    changeSelectedUserGroup(currentShare?.targetUserGroupIds);
    updateUserLists(currentShare?.targetUserIds);
  }, [currentShare]);

  useEffect(() => {
    switch (currentShare?.typeMark) {
      case SHARE_TYPE_MARK.SECTION:
        setTypeTitle(t('shareTitleSection'));
        break;
      case SHARE_TYPE_MARK.GROUP:
        setTypeTitle(t('shareTitleGroup'));
        break;
      case SHARE_TYPE_MARK.MEMO:
        setTypeTitle(t('shareTitleMemo'));
        break;
    }
  }, [t]);

  const handleIsPublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusIsPublic(event.target.checked);
  };

  const onShareUserGroupChange = (values: any) => {
    changeSelectedUserGroup(values);
  };

  const changeSelectedUserGroup = (values: any[] | undefined) => {
    if (values) {
      let selectedItems: any[] = [];
      if (userGroups)
        values.map((selectedItem) => {
          let userGroup = userGroups.filter((ug) => ug.guid === selectedItem.id)[0];
          selectedItems = [
            ...selectedItems,
            {
              id: selectedItem?.id,
              label: userGroup ? userGroup.name : selectedItem?.id
            }
          ];
        });
      setUserGroupIds(selectedItems);
    }
  };

  const isCurrentShareSettingChanged = () => {
    return currentShare?.isPublic !== statusIsPublic || !isArraiesEqualByGUID(currentShare?.targetUserGroupIds, userGroupIds) || !isArraiesEqualByGUID(currentShare?.targetUserIds, userIds);
  };

  const handleShareUserDeleted = (guid: string | undefined) => {
    updateUserLists(userIds?.filter((id: any) => id.guid !== guid));
  };

  const handleShareUserEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    triggerEnterKeyFunction(event, handleAddUser);
  };

  const handleAddUser = () => {
    if (userMark) {
      let newUser: UIShareTargetShape = {
        id: userMark?.id,
        label: userMark?.label
      };
      var filteredItems: any = userIds?.filter((ms) => ms.id !== userMark?.id);
      updateUserLists([...filteredItems, newUser]);
    }
  };

  const onSaveClick = () => {
    onShareSave(
      Object.assign({}, currentShare, {
        isPublic: statusIsPublic,
        targetUserGroupIds: userGroupIds,
        targetUserIds: userIds
      })
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
        padding: '0.2em',
        backgroundColor: 'secondary.light'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: editTopStatusHeight
        }}
      >
        <Box></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <Public
            sx={{
              marginLeft: 1,
              marginRight: 1,
              color: statusIsPublic ? 'primary.main' : 'secondary.main'
            }}
          ></Public>
          {userGroupIds && userGroupIds.length > 0 ? (
            <StyledBadge badgeContent={userGroupIds.length} color="primary" max={99}>
              <Group sx={{ marginLeft: 1, marginRight: 1, color: 'primary.main' }} />
            </StyledBadge>
          ) : (
            <Group sx={{ marginLeft: 1, marginRight: 1, color: 'secondary.main' }} />
          )}
          {userIds && userIds.length > 0 ? (
            <StyledBadge badgeContent={userIds.length} color="primary" max={99}>
              <Person sx={{ marginLeft: 1, marginRight: 1, color: 'primary.main' }} />
            </StyledBadge>
          ) : (
            <Person sx={{ marginLeft: 1, marginRight: 1, color: 'secondary.main' }} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'stretch'
          }}
        >
          <Typography sx={{ fontWeight: 'bolder', fontSize: '1.2em' }} display="block">{`${typeTitle}: ${shareTitle}`}</Typography>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pulice-content" id="pulice-header" sx={{ fontWeight: 'bolder' }}>
              <Public /> <Divider orientation="vertical" flexItem sx={{ ml: '0.3em', mr: '0.3em' }}></Divider>
              {t('shareTargeTypePublic')}
            </AccordionSummary>
            <AccordionDetails>
              <FormControlLabel control={<Checkbox checked={statusIsPublic} onChange={handleIsPublicChange} />} label={t('sharePublicLabel')} />
              <Typography sx={{ color: 'darkgray' }}>{t('sharePublicNotice')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="user-group-content" id="user-group-header" sx={{ fontWeight: 'bolder' }}>
              <Group /> <Divider orientation="vertical" flexItem sx={{ ml: '0.3em', mr: '0.3em' }}></Divider>
              {t('shareTargeTypeUserGroup')}
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                size="small"
                fullWidth
                multiple
                freeSolo
                options={userGroupOptions}
                // getOptionKey={(option) => option.id}
                // getOptionLabel={(option) => option.label}
                value={userGroupIds}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} label={t('shareGroupLabel')} placeholder={t('shareGroupPlaceHolder')} />}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option.id} label={option.label} />);
                }}
                onChange={(event, values) => {
                  onShareUserGroupChange(values);
                }}
                // isOptionEqualToValue={isUserGroupOptionEqualToValue}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="user-content" id="user-header" sx={{ fontWeight: 'bolder' }}>
              <Person /> <Divider orientation="vertical" flexItem sx={{ ml: '0.3em', mr: '0.3em' }}></Divider> {t('shareTargeTypeUser')}
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                size="small"
                fullWidth
                options={userOptions}
                autoComplete
                autoHighlight
                freeSolo
                onChange={(event, value) => setUserMark(value)}
                getOptionKey={(option: any) => option.id || ''}
                getOptionLabel={(option: any) => option.label || ''}
                renderOption={(props, option: any) => (
                  <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.id})
                  </Box>
                )}
                filterOptions={(options, state) => {
                  let newOptions: any = [];
                  options.forEach((userOption: any) => {
                    if (userOption && (userOption.label?.includes(state.inputValue.toLowerCase()) || userOption.id?.includes(state.inputValue.toLowerCase()))) newOptions.push(userOption);
                  });
                  return newOptions;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('shareUserLabel')}
                    placeholder={t('shareUserPlaceHolder')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password'
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      changeOptionBaseOnValue(event.target.value);
                    }}
                    onKeyDown={handleShareUserEnter}
                  />
                )}
                onInputChange={(event: object, value: string, reason: string) => {
                  if (reason === 'input') {
                    changeOptionBaseOnValue(value);
                  }
                }}
              />
              <ChipArray limit={10} values={userIds} onValueDeleted={handleShareUserDeleted}></ChipArray>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'stretch',
            padding: '0.5em'
          }}
        >
          <Button variant="contained" onClick={onSaveClick} disabled={!isCurrentShareSettingChanged()}>
            {t('buttonSave')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
