import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { fetchGroups, fetchGroup, remoteGroupSync, fetchGroupShares, fetchGroupShare, remoteGroupShareSync } from './groupAPI';
import { FetchResponseShape, HttpResponseType, MessageType, UIGroupShape, UIShareShape, UIShareStatusShape } from '../../../model/MemoModelShapes';

import { UPDATE_GROUP, SYNC_GROUP_SHARES, SYNC_GROUPS, SYNC_TRANSFER_GROUPS_BY_SECTION, UPDATE_SELECTED_PATH_BY_GROUP_ID, UPDATE_GROUP_SHARE } from '../../constants/ReduxConst';
import { DEFAULT_GROUP_GUID, DEFAULT_SECTION_GUID, retryInterval } from '../../../common/MetaSetting';
import { defaultGroup, getEmptyShare, SHARE_SETTING_TYPE_MARK, SHARE_TYPE_MARK } from '../../../common/MetaData';
import { findOneWMObjectFromAll, getWMObjectsNeedSyncForSource } from '../../../common/Utility';
import { changeSelectedSectionId } from '../section/sectionSlice';
import { appendMessageAsync } from '../../../appSlice';

export interface GroupState {
  groups: UIGroupShape[];
  groupShares: UIShareShape[];
  selectedGroupId: string;
  status: 'idle' | 'loading' | 'failed';
  // lastSyncTimestamp: number;
  isSyncing: boolean;
  currentGroup: UIGroupShape | undefined;
}

const initialState: GroupState = {
  groups: [],
  groupShares: [],
  selectedGroupId: '',
  status: 'idle',
  // lastSyncTimestamp: 0,
  isSyncing: false,
  currentGroup: undefined
};

const getGroupSharesNeedSyncForLocal = (localGroupShareList: UIShareShape[], remoteGroupShareList: UIGroupShape[]) => {
  var result: UIGroupShape[] = [];
  remoteGroupShareList.forEach((remoteGroupShare) => {
    var filteredItems = localGroupShareList.filter((gs) => gs.guid === remoteGroupShare.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteGroupShare.modifyTimestamp)) {
      result.push(remoteGroupShare);
    }
  });
  // console.log("Group need update for local", result);
  return result;
};

const changeGroupShareFlag = (groups: UIGroupShape[], group: UIGroupShape, shareSetting: UIShareShape) => {
  var filteredGroups = groups.filter((m) => m.guid !== shareSetting.guid);
  let bShare = shareSetting.isPublic || (shareSetting.targetUserGroupIds && shareSetting.targetUserGroupIds.length > 0) || (shareSetting.targetUserIds && shareSetting.targetUserIds.length > 0);
  var updatedGroups = [
    ...filteredGroups,
    Object.assign({}, group, {
      hasShare: bShare
    })
  ];
  return updatedGroups;
};

const getGroupsNeedSyncForLocal = (localGroupList: UIGroupShape[], remoteGroupList: UIGroupShape[]) => {
  var result: UIGroupShape[] = [];
  remoteGroupList.forEach((remoteGroup) => {
    var filteredItems = localGroupList.filter((g) => g.guid === remoteGroup.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteGroup.modifyTimestamp)) {
      result.push(remoteGroup);
    }
  });
  // console.log("Group need update for local", result);
  return result;
};

const getGroupsNeedSyncForRemote = (localGroupList: UIGroupShape[], remoteGroupList: UIGroupShape[]) => {
  var result: UIGroupShape[] = [];
  localGroupList.forEach((localGroup) => {
    if (localGroup.stateCode === 0) return;
    var filteredItems = remoteGroupList.filter((g) => g.guid === localGroup.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < localGroup.modifyTimestamp)) {
      result.push(localGroup);
    }
  });
  // console.log("Group need update for remote", result);
  return result;
};

const remoteGroupShareSyncRetry = (oidcFetch: Function, groupShareId: string, thunkAPI: any) => {
  let groupShareLocal = findOneWMObjectFromAll(thunkAPI.getState().group.groups, groupShareId);
  remoteGroupShareSync(oidcFetch, groupShareLocal).then((syncShareRespopnse: FetchResponseShape) => {
    if (syncShareRespopnse.responseType === HttpResponseType.SUCCESS) {
      syncShareRespopnse.content.then((groupShareRemote: any) => {
        if (groupShareRemote.syncResult === -1 && groupShareRemote.syncedShare) thunkAPI.dispatch(saveRemoteGroupShareToLocal(groupShareRemote.syncedShare));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: syncShareRespopnse.message }));
      setTimeout(() => remoteGroupShareSyncRetry(oidcFetch, groupShareId, thunkAPI), retryInterval);
    }
  });
};

const fetchGroupShareRetry = (oidcFetch: Function, groupShareId: string, thunkAPI: any) => {
  fetchGroupShare(oidcFetch, groupShareId).then((groupShareResponse: FetchResponseShape) => {
    if (groupShareResponse.responseType === HttpResponseType.SUCCESS) {
      groupShareResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveRemoteGroupShareToLocal(content));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: groupShareResponse.message }));
      setTimeout(() => fetchGroupShareRetry(oidcFetch, groupShareId, thunkAPI), retryInterval);
    }
  });
};

const syncAllGroupSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  let groupShares = [...thunkAPI.getState().group.groupShares];
  fetchGroupShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local group shares", state.group.groupShares);
      // console.log("Remote group shares", remoteGroupShares);
      response.content.then((remoteGroupShares: any) => {
        var groupSharesLocalNeedSync = getWMObjectsNeedSyncForSource(groupShares, remoteGroupShares);
        groupSharesLocalNeedSync.forEach((groupShareLocal) => {
          fetchGroupShareRetry(oidcFetch, groupShareLocal.guid, thunkAPI);
        });

        var groupSharesRemoteNeedSync = getWMObjectsNeedSyncForSource(remoteGroupShares, groupShares);
        groupSharesRemoteNeedSync.forEach((groupShareLocal) => {
          remoteGroupShareSyncRetry(oidcFetch, groupShareLocal.guid, thunkAPI);
        });
        // console.log("Group share need update for remote", groupSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => syncAllGroupSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchGroupSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchGroupShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local group shares", state.group.groupShares);
      // console.log("Remote group shares", remoteGroupShares);
      response.content.then((remoteGroupShares: any) => {
        remoteGroupShares.forEach((groupShareLocal: any) => {
          fetchGroupShareRetry(oidcFetch, groupShareLocal.guid, thunkAPI);
        });
        // console.log("Group share need update for remote", groupSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => fetchGroupSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllGroupSharesAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_GROUP_SHARES, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchGroupSharesRetry(oidcFetch, thunkAPI);

  return currentTime;
});

const remoteGroupSyncRetry = (oidcFetch: Function, groupId: string, thunkAPI: any) => {
  let groupLocal = findOneWMObjectFromAll(thunkAPI.getState().group.groups, groupId);
  remoteGroupSync(oidcFetch, groupLocal).then((groupResponse: FetchResponseShape) => {
    if (groupResponse.responseType === HttpResponseType.SUCCESS) {
      groupResponse.content.then((content: any) => {
        if (content.syncResult === -1 && content.syncedGroup) thunkAPI.dispatch(saveGroupToLocal(content.syncedGroup));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: groupResponse.message }));
      setTimeout(() => remoteGroupSyncRetry(oidcFetch, groupId, thunkAPI), retryInterval);
    }
  });
};

const fetchGroupRetry = (oidcFetch: Function, groupId: string, thunkAPI: any) => {
  fetchGroup(oidcFetch, groupId).then((groupResponse: FetchResponseShape) => {
    if (groupResponse.responseType === HttpResponseType.SUCCESS) {
      groupResponse.content.then((content: any) => thunkAPI.dispatch(saveGroupToLocal(content)));
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: groupResponse.message }));
      setTimeout(() => fetchGroupRetry(oidcFetch, groupId, thunkAPI), retryInterval);
    }
  });
};

const syncAllGroupsRetry = (oidcFetch: Function, thunkAPI: any) => {
  let groups = [...thunkAPI.getState().group.groups];
  fetchGroups(oidcFetch, 0).then((groupResponse: FetchResponseShape) => {
    if (groupResponse.responseType === HttpResponseType.SUCCESS) {
      groupResponse.content.then((remoteGroups: any) => {
        var groupsLocalNeedSync = getGroupsNeedSyncForLocal(groups, remoteGroups);
        groupsLocalNeedSync.forEach((localGroup) => {
          fetchGroupRetry(oidcFetch, localGroup.guid, thunkAPI);
        });

        var groupsRemoteNeedSync = getGroupsNeedSyncForRemote(groups, remoteGroups);
        groupsRemoteNeedSync.forEach((remoteGroup) => {
          remoteGroupSyncRetry(oidcFetch, remoteGroup.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: groupResponse.message }));
      setTimeout(() => syncAllGroupsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchGroupsRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchGroups(oidcFetch, 0).then((groupsResponse: FetchResponseShape) => {
    if (groupsResponse.responseType === HttpResponseType.SUCCESS) {
      groupsResponse.content.then((remoteGroups: any) => {
        remoteGroups.forEach((remoteGroup: any) => {
          thunkAPI.dispatch(saveGroupToLocal(remoteGroup));
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: groupsResponse.message }));
      setTimeout(() => fetchGroupsRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllGroupsAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_GROUPS, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchGroupsRetry(oidcFetch, thunkAPI);

  return currentTime;
});

export const transferToSectionBySectionIdAsync = createAsyncThunk<number, any, { state: RootState }>(SYNC_TRANSFER_GROUPS_BY_SECTION, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  let localGroups = [...thunkAPI.getState().group.groups];
  localGroups
    .filter((lg: any) => lg.sectionId === param.sectionId)
    .forEach((group: any) => {
      let groupLocal = Object.assign({}, group, {
        sectionId: DEFAULT_SECTION_GUID,
        modifyTimestamp: currentTime
      });
      thunkAPI.dispatch(saveGroupToLocal(groupLocal));
      remoteGroupSyncRetry(param.oidcFetch.guid, groupLocal, thunkAPI);
    });

  return currentTime;
});

export const changeSelectedPathByGroupIdAsync = createAsyncThunk<number, string, { state: RootState }>(UPDATE_SELECTED_PATH_BY_GROUP_ID, async (groupId, thunkAPI) => {
  var foundGroups = thunkAPI.getState().group.groups.filter((g) => g.guid === groupId);
  if (foundGroups.length > 0) {
    thunkAPI.dispatch(changeSelectedSectionId(foundGroups[0].sectionId));
  }

  return 0;
});

export const updateGroupAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_GROUP, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let groupLocal = Object.assign({}, param.group, {
    modifyTimestamp: currentTime
  });
  if (groupLocal.createTimestamp === 0) groupLocal.createTimestamp = currentTime;
  thunkAPI.dispatch(saveGroupToLocal(groupLocal));
  remoteGroupSyncRetry(param.oidcFetch, groupLocal.guid, thunkAPI);

  return currentTime;
});

export const updateGroupShareAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_GROUP_SHARE, async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  let currentTime = new Date().getTime();

  let newShare: any;
  let group = findOneWMObjectFromAll(state.group.groups, param.shareObject.guid);
  let shareSetting = findOneWMObjectFromAll(state.group.groupShares, param.shareObject.guid);
  if (shareSetting) {
    newShare = Object.assign({}, shareSetting, param.shareObject.setting, {
      title: group?.name,
      createTimestamp: currentTime,
      modifyTimestamp: currentTime
    });
  } else {
    newShare = Object.assign({}, param.shareObject.setting, {
      title: group?.name,
      createTimestamp: currentTime,
      modifyTimestamp: currentTime
    });
  }

  thunkAPI.dispatch(saveEditGroupShareToLocal(Object.assign({}, param.shareObject, { newShare: newShare })));
  remoteGroupShareSyncRetry(param.oidcFetch, newShare.guid, thunkAPI);

  return currentTime;
});

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    changeSelectedGroupId: (state, action: PayloadAction<string>) => {
      if (state.selectedGroupId !== action.payload) {
        state.selectedGroupId = action.payload;
        let group = findOneWMObjectFromAll(state.groups, action.payload);
        if (group) {
          state.currentGroup = Object.assign({}, group);
        } else {
          if (action.payload === DEFAULT_GROUP_GUID) {
            state.currentGroup = defaultGroup;
          } else {
            state.currentGroup = undefined;
          }
        }
      }
    },
    saveEditGroupShareToLocal: (state, action: PayloadAction<any>) => {
      let group = findOneWMObjectFromAll(state.groups, action.payload.guid);
      if (group) {
        var updatedGroups: any[] = changeGroupShareFlag(state.groups, group, action.payload.setting);
        state.groups = updatedGroups.sort((a, b) => a.name.localeCompare(b.name));
      }

      var filteredItems = state.groupShares.filter((m: any) => m.guid !== action.payload.guid);
      state.groupShares = [...filteredItems, action.payload.newShare];
    },
    saveRemoteGroupShareToLocal: (state, action: PayloadAction<UIShareStatusShape>) => {
      let groupShare = getEmptyShare();
      let group = findOneWMObjectFromAll(state.groups, action.payload.guid);
      groupShare.title = group?.name;
      groupShare.typeMark = SHARE_TYPE_MARK.GROUP;
      groupShare.guid = action.payload.guid;
      groupShare.createTimestamp = action.payload.createTimestamp;
      groupShare.modifyTimestamp = action.payload.modifyTimestamp;
      let targetUserGroupIds: any[] = [];
      let targetUserIds: any[] = [];
      action.payload.shareStatus &&
        action.payload.shareStatus.forEach((shareInfo) => {
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.PUBLIC) groupShare.isPublic = true;
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER_GROUP) targetUserGroupIds.push({ id: shareInfo.targetId, label: '' });
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER) targetUserIds.push({ id: shareInfo.targetId, label: '' });
        });
      groupShare.targetUserGroupIds = targetUserGroupIds;
      groupShare.targetUserIds = targetUserIds;
      var filteredItems = state.groupShares.filter((m) => m.guid !== action.payload.guid);
      state.groupShares = [...filteredItems, groupShare];

      if (group) {
        var updatedGroups: any[] = changeGroupShareFlag(state.groups, group, groupShare);
        state.groups = updatedGroups.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    saveGroupToLocal: (state, action: PayloadAction<UIGroupShape>) => {
      var filteredItems = state.groups.filter((g) => g.guid !== action.payload.guid);
      var updatedAddedGroups = [...filteredItems, Object.assign({}, action.payload)];
      let groupShare = findOneWMObjectFromAll(state.groupShares, action.payload.guid);
      if (groupShare) {
        updatedAddedGroups = changeGroupShareFlag(updatedAddedGroups, action.payload, groupShare);
      }
      state.groups = updatedAddedGroups.sort((a, b) => a.name.localeCompare(b.name));

      if (state.selectedGroupId === action.payload.guid) {
        let group = findOneWMObjectFromAll(state.groups, action.payload.guid);
        state.currentGroup = Object.assign({}, group);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGroupsAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllGroupsAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllGroupsAsync.rejected, (state) => {
        console.log('syncGroupAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(transferToSectionBySectionIdAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(transferToSectionBySectionIdAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(transferToSectionBySectionIdAsync.rejected, (state) => {
        console.log('transferToSectionBySectionIdAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(changeSelectedPathByGroupIdAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(changeSelectedPathByGroupIdAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(changeSelectedPathByGroupIdAsync.rejected, (state) => {
        console.log('changeSelectedPathByGroupIdAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateGroupAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateGroupAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateGroupAsync.rejected, (state) => {
        console.log('updateGroupAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateGroupShareAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateGroupShareAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateGroupShareAsync.rejected, (state) => {
        console.log('updateGroupShareAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const { changeSelectedGroupId, saveEditGroupShareToLocal, saveRemoteGroupShareToLocal, saveGroupToLocal } = groupSlice.actions;

export const selectedGroupId = (state: RootState) => state.group.selectedGroupId;
export const selectGroups = (state: RootState) => state.group.groups;
export const selectGroupShares = (state: RootState) => state.group.groupShares;
export const selectCurrentGroup = (state: RootState) => state.group.currentGroup;

export default groupSlice.reducer;
