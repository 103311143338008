import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const AppMain = ({ children }: ILayoutProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Box component="main" sx={{ width: '100%', height: '100%' }}>
      {children}
    </Box>
  );
};
