import { DEFAULT_REQUEST_OPTIONS } from '../../../common/requestParameter';
import { wmServerAddress } from '../../../common/MetaSetting';
import { UIUserGroupShape, UserGroupSyncRequestShape } from '../../../model/MemoModelShapes';
import { fetchHttpResponseHandler, fetchHttpExceptionHandler } from '../../../util/UtilFunction';

export function fetchUserGroups(oidcFetch: Function, lastSyncTimestamp = 0) {
  // console.log("userGroup lastSyncTimestamp", lastSyncTimestamp);
  return oidcFetch(`${wmServerAddress}/userGroup/all/${lastSyncTimestamp}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function fetchUserGroup(oidcFetch: Function, userGroupId: string) {
  return oidcFetch(`${wmServerAddress}/userGroup/${userGroupId}`, DEFAULT_REQUEST_OPTIONS)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}

export function remoteUserGroupSync(oidcFetch: Function, userGroup: UIUserGroupShape) {
  var userGroupSyncRequest: UserGroupSyncRequestShape = {
    syncType: 0,
    GUID: userGroup.guid,
    sourceUserGroup: userGroup
  };

  var myInit = Object.assign({}, DEFAULT_REQUEST_OPTIONS, {
    method: 'PUT',
    body: JSON.stringify(userGroupSyncRequest)
  });

  return oidcFetch(`${wmServerAddress}/userGroup/sync`, myInit)
    .then((response: any) => fetchHttpResponseHandler(response))
    .catch(fetchHttpExceptionHandler);
}
