import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

export const LoadingComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Box>{t('loadingPrompt')}</Box>
    </Box>
  );
};

export default LoadingComponent;
